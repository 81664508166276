<template>
  <div class="login-view">
    <div class="login-wrap">
      <div class="login-title">登录</div>
      <el-form class="login-form" label-width="130px" label-position="left">
        <el-form-item label="企业类型">
          <el-select
            v-model="form.type"
            placeholder="请选择企业类型"
            style="width: 100%"
          >
            <el-option
              :value="item.id"
              :label="item.name"
              v-for="item in companyTypes"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input
            v-model="form.phone"
            placeholder="请输入手机号码"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="手机验证码">
          <div class="dycode-row">
            <el-input
              v-model="form.message"
              placeholder="请输入手机验证码"
              clearable
            ></el-input>
            <div class="dycode-box">
              <el-button
                v-if="cansendtimeout == 0"
                type="primary"
                @click="sendMessage"
                >发送验证码</el-button
              >
              <el-button v-else disabled
                >{{ cansendtimeout }}秒后重新发送</el-button
              >
            </div>
          </div>
        </el-form-item>
        <el-form-item label="动态验证码">
          <div class="dycode-row">
            <el-input
              v-model="form.dycode"
              placeholder="请输入验证码"
              clearable
            ></el-input>
            <div class="dycode-box dy-column-layout">
              <div class="dycode-image">
                <img v-if="dycode_image" :src="dycode_image" alt="" />
              </div>
              <div>
                <el-link :underline="false" @click="getDycodeImage"
                  >看不清？换一张</el-link
                >
              </div>
            </div>
          </div>
        </el-form-item>
        <div class="forget-row">
          <div>
            <el-link
              type="primary"
              :underline="false"
              @click="$router.push('/login')"
              >切换账号登录>></el-link
            >
          </div>
        </div>
        <div class="action-btn-row">
          <button class="login-action-btn confirm" type="button" @click="login">
            登录
          </button>
          <button class="login-action-btn cancel" type="button" @click="cancel">
            取消
          </button>
        </div>
        <div class="bottom-row">
          <div class="no-account-tip">
            没有帐号，立即
            <el-link
              type="primary"
              :underline="false"
              @click="$router.push('/register')"
              >注册</el-link
            >
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
const companyTypes = [
  {
    id: 0,
    name: "集成类",
  },
  {
    id: 1,
    name: "供应商",
  },
  {
    id: 2,
    name: "招标方",
  },
];
export default {
  data() {
    return {
      form: {
        type: 0,
        phone: "",
        message: "", // 登录验证码
        dycode: "",
      },

      dycode_image: null,

      cansendtimeout: 0,
      sendtimer: null,
    };
  },
  computed: {
    companyTypes() {
      return companyTypes;
    },
  },
  mounted() {
    this.getDycodeImage();
  },
  methods: {
    sendMessage() {
      if (this.form.phone == "") {
        this.$message.warning("请输入手机号");
        return;
      }
      this.$axios.post(`/sms/sendPhoneLogin/${this.form.phone}`).then((res) => {
        if (res.state == 200) {
          this.cansendtimeout = 60;
          this.sendtimer = setInterval(() => {
            if (this.cansendtimeout > 0) {
              this.cansendtimeout--;
            } else {
              clearInterval(this.sendtimer);
              this.cansendtimeout = 0;
            }
          }, 1000);
        } else {
          alert(res.message);
        }
      });
    },
    getDycodeImage() {
      this.$axios
        .get("/verifcode", {
          responseType: "blob",
        })
        .then((res) => {
          this.dycode_image = URL.createObjectURL(res);
        });
    },
    // 校验动态码
    checkDyCode() {
      return new Promise((resolve) => {
        this.$axios.post(`/checkVerifCode/${this.form.dycode}`).then((res) => {
          if (res.state == 200) {
            resolve();
          } else {
            alert(res.message);
          }
        });
      });
    },
    login() {
      if (this.form.dycode == "") {
        this.$message.warning("请输入动态码");
        return;
      }
      this.checkDyCode().then(() => {
        if (this.form.phone == "") {
          this.$message.warning("请输入手机号");
          return;
        }
        if (this.form.message == "") {
          this.$message.warning("请输入验证码");
          return;
        }
        this.$axios
          .post(
            `/member/loginPhone/${this.form.message}/${this.form.phone}/${this.form.type}`
          )
          .then((res) => {
            if (res.state == 200) {
              localStorage.user = JSON.stringify(res.data);
              this.$store.commit("setUserInfo", res.data);
              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              } else {
                const route = {
                  0: "/a-company",
                  1: "/control/companyb",
                  2: "/control/companyc",
                };
                this.$router.push(route[res.data.type]);
              }
            } else {
              this.$message.error(res.message);
            }
          });
      });
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.login-view {
  background: rgba(4, 17, 39, 0.6);
  padding: 100px 0 114px 0;
  .login-wrap {
    width: 1050px;
    height: 720px;
    background: #ffffff;
    margin: 0 auto;
    padding: 50px 0 10px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    .login-title {
      color: #333333;
      line-height: 50px;
      font-size: 36px;
      text-align: center;
      letter-spacing: 20px;
      margin-bottom: 70px;
    }
    .no-account-tip {
      position: absolute;
      right: 250px;
      bottom: 40px;
    }
    .login-form {
      width: 540px;
      margin: 0 auto;
      .el-form-item {
        margin-bottom: 30px;
      }
      .dycode-row {
        display: flex;
        .el-input {
          flex: 1;
          height: 40px;
        }
        .dycode-box {
          width: 170px;
          text-align: center;
        }
        .dy-column-layout {
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
        }
        .dycode-image {
          height: 40px;
          img {
            height: 100%;
          }
        }
      }

      .forget-row {
        margin-top: -20px;
        text-align: right;
      }
      .action-btn-row {
        text-align: center;
        margin-top: 50px;
      }
      .bottom-row {
        margin-top: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .no-account-tip-row {
      }
    }

    .login-action-btn {
      width: 157px;
      height: 53px;
      line-height: 53px;
      font-size: 26px;
      color: #333333;
      outline: none;
      margin: 0 20px;
      &.confirm {
        background: #004375;
        border: 1px solid #004375;
        color: #ffffff;
      }
      &.cancel {
        box-shadow: 0px 2px 9px 0px rgba(32, 114, 209, 0.26);
        border: 1px solid #004375;
      }
    }
    .link {
      color: #3883ff;
      text-decoration: none;
      font-size: 16px;
    }
  }
}
</style>